import React, { FC, useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import './NewsletterPage.css';
import { useI18n } from '../../@context/i18n';
import InfosPanel from '../../components/InfosPanel/InfosPanel';
import { Language } from '../../utils/Languages';
import Hero from '../../components/Hero/Hero';

const NewsletterPage: FC<NewsletterPageDto> = ({
  Images,
  IntroTitle,
  IntroText,
  Breadcrumbs,
  Delay,
  FormTitle,
  InfoPanels,
  Title,
  ThanksText,
}) => {
  const { translate } = useI18n();
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [organization, setOrganization] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [done, setDone] = useState(false);
  const [error, setError] = useState<string>();
  const [canSubmit, setCanSubmit] = useState(false);

  useEffect(() => {
    const invalid =
      !firstName || !lastName || !email || !organization || !city || !country;

    if (!invalid) {
      setCanSubmit(true);
    }
  }, [firstName, lastName, email, organization, city, country, canSubmit]);

  const submit = async (e: React.FormEvent) => {
    e.preventDefault();

    const token = await recaptchaRef.current?.executeAsync();

    // eslint-disable-next-line no-unused-expressions
    recaptchaRef.current?.reset();

    const lang = Language;
    const obj = {
      FirstName: firstName,
      LastName: lastName,
      Email: email,
      Organization: organization,
      City: city,
      Country: country,
      Token: token,
    };

    const requestOptions = {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(obj),
    };

    const response = await fetch(`/api/newsletter/${lang}`, requestOptions);
    if (response.status === 200) {
      setDone(true);
      setError('');

      return;
    }

    setDone(false);
    setError(translate('newsletter-form.error'));
  };

  return (
    <div className='newsletter'>
      <div className='newsletter__content'>
        <Hero
          Images={Images}
          IntroTitle={IntroTitle}
          IntroText={IntroText}
          breadcrumb={Breadcrumbs}
          Delay={Delay}
        />

        <div className='newsletter__panels'>
          {FormTitle && <h5 className='newsletter__form-title'>{FormTitle}</h5>}
          <form className='newsletter__form' onSubmit={submit}>
            <label className='newsletter__form-label' htmlFor={firstName}>
              <span className='newsletter__form-legend'>
                {translate('newsletter-form.firstName')}
              </span>
              <input
                type='text'
                id='firstName'
                className='newsletter__form-input'
                value={firstName || ''}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </label>
            <label className='newsletter__form-label' htmlFor={lastName}>
              <span className='newsletter__form-legend'>
                {translate('newsletter-form.lastName')}
              </span>
              <input
                type='text'
                id='lastName'
                className='newsletter__form-input'
                value={lastName || ''}
                onChange={(e) => setLastName(e.target.value)}
              />
            </label>
            <label className='newsletter__form-label' htmlFor={email}>
              <span className='newsletter__form-legend'>
                {translate('newsletter-form.email')}
              </span>
              <input
                type='email'
                id='email'
                className='newsletter__form-input'
                value={email || ''}
                onChange={(e) => setEmail(e.target.value)}
              />
            </label>
            <label className='newsletter__form-label' htmlFor='organization'>
              <span className='newsletter__form-legend'>
                {translate('newsletter-form.organization')}
              </span>
              <input
                type='text'
                id='organization'
                className='newsletter__form-input'
                value={organization || ''}
                onChange={(e) => setOrganization(e.target.value)}
              />
            </label>
            <label className='newsletter__form-label' htmlFor='city'>
              <span className='newsletter__form-legend'>
                {translate('newsletter-form.city')}
              </span>
              <input
                type='text'
                id='city'
                className='newsletter__form-input'
                value={city || ''}
                onChange={(e) => setCity(e.target.value)}
              />
            </label>
            <label className='newsletter__form-label' htmlFor='country'>
              <span className='newsletter__form-legend'>
                {translate('newsletter-form.country')}
              </span>
              <input
                type='text'
                id='country'
                className='newsletter__form-input'
                value={country || ''}
                onChange={(e) => setCountry(e.target.value)}
              />
            </label>
            {!!error && <div className='newsletter__form-error'>{error}</div>}

            {done ? (
              <div
                className='newsletter__form-thanks'
                dangerouslySetInnerHTML={{ __html: ThanksText }}
              />
            ) : (
              <button
                type='submit'
                onClick={submit}
                className='newsletter__form-submit-link'
                disabled={!canSubmit}
              >
                {translate('newsletter-form.submit-link')}
              </button>
            )}

            <input className='newsletter__form-submit' type='submit' />
          </form>

          <ReCAPTCHA
            ref={recaptchaRef}
            size='invisible'
            sitekey={process.env.REACT_APP_RECAPTCHA ?? ''}
          />

          <div className='newsletter__imfo-boxes'>
            {InfoPanels.length >= 1 && (
              <InfosPanel Title={Title} InfoPanels={InfoPanels} type='' />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsletterPage;
