import * as React from 'react';
import { useContext, useEffect, useState, createContext, FC } from 'react';
import { Language } from 'src/utils/Languages';
import { useApi } from './Api';

export type IPage = IPageDto & { endpoint: string };
type App = {
	app?: AppDto;
	// page?: IPage;
};
export const AppContext = createContext<App>({} as never);
export const App: FC = ({ children }) => {
	const { get } = useApi();
	const [app, setApp] = useState<AppDto>();
	// const [page, setPage] = useState<IPage>();

	useEffect(() => {
		(async () => {
			const appElt = document.getElementById('app');
			const json = appElt?.innerHTML;
			let dto: AppDto | undefined;

			try {
				dto = (json && (JSON.parse(json) as AppDto)) || undefined;
			} catch (e) {
				// console.info("malformed JSON on page");
			}

			if (!dto) {
				dto = await get<AppDto>(`/${Language}/api/app`);
			}
			setApp(dto);
		})();
	}, [setApp, get]);

	const value = { app };
	return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useApp = (): App => useContext(AppContext);
