import React from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import { BrowserRouter, Switch, Redirect, Route } from 'react-router-dom';
import { App as AppContext } from './@context/App';
import { Api } from './@context/Api';
import Header from './components/Header';
import stylesheet from './constants/stylesheet';
import background from './assets/background.png';
import { Language } from './utils/Languages';
import Pages from './Pages';
import HeaderMobile from './components/HeaderMobile/HeaderMobile';
import { I18n } from './@context/i18n';
import './constants/custom-styles.css';

const useStyles = makeStyles(() =>
	createStyles({
		'@global': {
			'html, body': {
				position: 'relative',
				width: '100%',
				height: '100%',
				// "-webkit-font-smoothing": "antialiased!important",
				// "-moz-osx-font-smoothing": "grayscale",
				color: 'black',
				...stylesheet.Text,
			},
			'ol, ul': {
				fontSize: 'inherit',
				listStyle: 'none',
			},
			'#root': {
				position: 'relative',
				width: '100%',
				height: '100%',
			},
			a: {
				color: 'inherit',
				textDecoration: 'none',
			},
		},
		app: {
			position: 'relative',
			width: '100%',
			height: '100%',
		},
		appInner: {
			overflow: 'hidden',
		},
		backgroundImage: {
			position: 'fixed',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,

			'& img': {
				width: '100%',
				height: '100%',
				objectFit: 'cover',
			},
		},
		container: {
			position: 'relative',
			zIndex: 1,
			opacity: 1,
			transition: 'opacity 0.5s ease',

			'[data-hide="true"] &': {
				opacity: 0,
				pointerEvents: 'none',
			},
		},
	})
);

const App = (): JSX.Element => {
	const cls = useStyles();

	return (
		<BrowserRouter>
			<Api>
				<AppContext>
					<I18n language={Language}>
						<div className={cls.app}>
							<div className={cls.backgroundImage}>
								<img src={background} alt='' />
							</div>
							<div className={cls.appInner}>
								<Header />
								<HeaderMobile />
								<div className={cls.container}>
									<Switch>
										<Redirect path='/' exact to={`/${Language}`} />

										<Route component={Pages} />
									</Switch>
								</div>
							</div>
						</div>
					</I18n>
				</AppContext>
			</Api>
		</BrowserRouter>
	);
};

export default App;
