import React, { FC } from "react";
import AccordionPanel from "./AccordionPanel/AccordionPanel";
import ParagraphPanel from "./ParagraphPanel/ParagraphPanel";
import InfosPanel from "./InfosPanel/InfosPanel";
import ImagesPanel from "./ImagesPanel/ImagesPanel";
import VideoPanel from "./VideoPanel/VideoPanel";
import PartnersPanel from "./PartnersPanel/PartnersPanel";
import SupportsPanel from "./SupportsPanel/SupportsPanel";

const Panel: FC<IPanelDto> = ({ ...panel }) => {
  switch (panel.type) {
    case "AccordionPanel":
      return <AccordionPanel {...(panel as AccordionPanelDto)} />;
    case "ParagraphPanel":
      return <ParagraphPanel {...(panel as ParagraphPanelDto)} />;
    case "InfosPanel":
      return <InfosPanel {...(panel as InfosPanelDto)} />;
    case "ImagesPanel":
      return <ImagesPanel {...(panel as ImagesPanelDto)} />;
    case "VideoPanel":
      return <VideoPanel {...(panel as VideoPanelDto)} />;
    case "PartnersPanel":
      return <PartnersPanel {...(panel as PartnersPanelDto)} />;
    case "SupportsPanel":
      return <SupportsPanel {...(panel as SupportsPanelDto)} />;

    default:
      return null;
  }
};

export default Panel;
