import React, { FC } from "react";
import { Link } from "react-router-dom";
import "./Calendar.css";

const Calendar: FC<KalendarPageDto> = ({ Title, Panels }) => {
  return (
    <div className="calendar">
      <article className="calendar__content">
        {Title && <h5 className="calendar__title">{Title}</h5>}
        <div className="calendar__events">
          {Panels.map((e, i) => {
            switch (e.type) {
              case "KalendarTitleEvent": {
                const titleEvent = e as KalendarTitleEventDto;
                return (
                  <div
                    key={i}
                    className="calendar__event calendar__event--link"
                  >
                    <Link to={titleEvent.Link} className="calendar__event-link">
                      {titleEvent.Text}
                    </Link>
                  </div>
                );
              }
              case "KalendarEvent": {
                const event = e as KalendarEventDto;
                return (
                  <div key={i} className="calendar__event">
                    <div className="calendar__event-date">{event.Date}</div>
                    <div
                      className="calendar__event-text"
                      dangerouslySetInnerHTML={{ __html: event.Text }}
                    />
                  </div>
                );
              }
              default:
                return null;
            }
          })}
        </div>
      </article>
    </div>
  );
};

export default Calendar;
