import React, { FC } from 'react';
import { ClassNames } from 'src/utils/ClassNames';
import { Link } from 'react-router-dom';
import Image from '../../components/Image';
import background from '../../assets/platzhalter-bild.png';
import './BoxItem.css';

const BoxItem: FC<OverviewBoxDto> = ({
  PinkFlag,
  BlackFlag,
  Link: link,
  Image: boxImage,
  LinkURL,
  Description,
  Title,
}) => {
  const hasFalgs = PinkFlag !== '' || BlackFlag !== '';
  const isExternalLink = link && link !== '';
  const sizes =
    '(max-width: 767px) 100w,' +
    '(min-width: 768px) and (max-width: 1240px) and (orientation: portrait) calc(100%/2),' +
    '(min-width: 768px) and (max-width: 1240px) and (orientation: landscape) calc(100%/3),' +
    '500px';

  return (
    <article className='box-item'>
      {hasFalgs && (
        <div className={ClassNames('box-item', 'flags')}>
          {PinkFlag && (
            <div className={ClassNames('box-item', 'flag', 'pink')}>
              <span>{PinkFlag}</span>
            </div>
          )}
          {BlackFlag && (
            <div className={ClassNames('box-item', 'flag', 'black')}>
              <span>{BlackFlag}</span>
            </div>
          )}
        </div>
      )}
      <div className='box-item__figure'>
        {boxImage && <Image pictureRef={boxImage} sizes={sizes} />}
        {!boxImage && <img src={background} alt='background' />}
        {LinkURL && !isExternalLink && (
          <Link
            to={LinkURL}
            className={ClassNames('box-item', 'figure-link')}
          />
        )}
        {LinkURL && isExternalLink && (
          // eslint-disable-next-line jsx-a11y/anchor-has-content
          <a
            aria-label='external link'
            href={LinkURL}
            className={ClassNames('box-item', 'figure-link')}
            target='_blank'
            rel='noreferrer noopener'
          />
        )}
      </div>
      <div className='box-item__content-outer'>
        <div className='box-item__content'>
          {Title && !LinkURL && (
            <h2 className={ClassNames('box-item', 'title')}>{Title}</h2>
          )}
          {Title && LinkURL && (
            <h2 className={ClassNames('box-item', 'title')}>
              {!isExternalLink && (
                <Link
                  to={LinkURL}
                  className={ClassNames(
                    'box-item',
                    'title-link',
                    !Description && 'arrow'
                  )}
                >
                  {Title}
                </Link>
              )}

              {isExternalLink && (
                <a
                  href={LinkURL}
                  target='_blank'
                  rel='noreferrer noopener'
                  className={ClassNames(
                    'box-item',
                    'title-link',
                    !Description && 'external'
                  )}
                >
                  {Title}
                </a>
              )}
            </h2>
          )}

          {Description && (
            <div className='box-item__text-outer'>
              <div
                className='box-item__text'
                dangerouslySetInnerHTML={{ __html: Description }}
              />
              {LinkURL && !isExternalLink && (
                <Link
                  to={LinkURL}
                  className={ClassNames('box-item', 'link', 'arrow')}
                />
              )}
              {LinkURL && isExternalLink && (
                // eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/control-has-associated-label
                <a
                  href={LinkURL}
                  className={ClassNames('box-item', 'link', 'external')}
                  target='_blank'
                  rel='noreferrer noopener'
                />
              )}
            </div>
          )}
        </div>
      </div>
    </article>
  );
};

export default BoxItem;
