/* eslint-disable no-underscore-dangle */
import * as React from "react";
import "./SearchPage.css";
import { FC, useState, useRef } from "react";
import { useI18n } from "src/@context/i18n";
import { ClassNames } from "src/utils/ClassNames";
import Spinner from "../../components/Spinner/Spinner";

type Query = {
  ETag: string;
  count: number;
  inputEncoding: string;
  outputEncoding: string;
  safe: string;
  searchTerms: string;
  startIndex: number;
  title: string;
  totalResults: number;
};
type SearchResults = {
  ETag: string;
  items: SearchResultsItem[];
  queries: { request: Query[]; nextPage?: Query[]; previousPage?: Query[] };
};

type SearchResultsItem = {
  ETag: string;
  cacheId: string;
  displayLink: string;
  fileFormat: string;
  formattedUrl: string;
  htmlFormattedUrl: string;
  htmlSnippet: string;
  htmlTitle: string;
  image: string;
  kind: string;
  labels: string;
  link: string;
  mime: string;
  pagemap: { cse_thumbnail: Array<{ width: string; height: string }> };
  cse_image: Array<{ src: string }>;
  cse_thumbnail: Array<{ width: string; height: string; src: string }>;
  snippet: string;
  title: string;
};
type State = {
  input?: string;
  query?: string;
  page: number;
  loading: boolean;
  searchResults?: SearchResults;
};

const SearchPage: FC<SearchPageDto> = () => {
  const [page, setPage] = useState(1);
  const [input, setInput] = useState<string>();
  const [query, setQuery] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState<SearchResults>();
  const { translate } = useI18n();

  const firstRender = useRef(true);

  React.useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    setLoading(true);
    (() => {
      const requestOptions = {
        headers: { "Content-Type": "application/json" },
        method: "GET",
      };
      fetch(`/api/search?query=${query}&page=${page}`, requestOptions)
        .then((response) => {
          if (!response) {
            setLoading(false);
          }
          return response.json().then((json) => {
            setSearchResults(json);
            setLoading(false);
          });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          if (!document.body) {
            return;
          }

          if (document.body.scrollTop > 0) {
            document.body.scrollTop = 0;
          }
          setLoading(false);
        });
    })();
  }, [page, query]);

  const nextPage = () => {
    if (
      !searchResults ||
      !searchResults.queries.nextPage ||
      !searchResults.queries.nextPage.length
    ) {
      return;
    }
    const $page = searchResults.queries.nextPage[0].startIndex;
    setPage($page);
  };

  const previousPage = () => {
    if (
      !searchResults ||
      !searchResults.queries.previousPage ||
      !searchResults.queries.previousPage.length
    ) {
      return;
    }
    const $page = searchResults.queries.previousPage[0].startIndex;
    setPage($page);
  };

  const renderResults = (result: SearchResults) => {
    const next = result.queries.nextPage && result.queries.nextPage.length > 0;
    const previous =
      result.queries.previousPage && result.queries.previousPage.length > 0;

    return (
      <>
        <h5 className="search-page__title">
          <div
            dangerouslySetInnerHTML={{
              __html: translate("searchPage.results", query!),
            }}
          />
        </h5>
        <div className="search-page__results-inner">
          {result.items.map((item, i) => {
            return (
              <article key={i} className="search-page__result">
                {item.link ? (
                  <a
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="search-page__result-title"
                  >
                    <span
                      dangerouslySetInnerHTML={{ __html: item.htmlTitle }}
                    />
                  </a>
                ) : (
                  <h4
                    className="search-page__result-title search-page__result-title--no-link"
                    dangerouslySetInnerHTML={{ __html: item.htmlTitle }}
                  />
                )}
                <div
                  className="search-page__result-text"
                  dangerouslySetInnerHTML={{ __html: item.htmlSnippet }}
                />
                {item.link && (
                  <a
                    href={item.link}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="search-page__result-link search-page__result-link--arrow-before"
                  >
                    {translate("searchPage.toPage")}
                  </a>
                )}
              </article>
            );
          })}
          {(previous || next) && (
            <div className="search-page__result-pages">
              {previous && (
                <button
                  type="button"
                  onClick={previousPage}
                  className={ClassNames(
                    "search-page",
                    "result-link",
                    "arrow-after",
                    "previous"
                  )}
                >
                  {translate("searchPage.previousPage")}
                </button>
              )}
              {next && (
                <button
                  type="button"
                  onClick={nextPage}
                  className={ClassNames(
                    "search-page",
                    "result-link",
                    "arrow-before",
                    "next"
                  )}
                >
                  {translate("searchPage.nextPage")}
                </button>
              )}
            </div>
          )}
        </div>
      </>
    );
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setQuery(input);
    setSearchResults(undefined);
  };

  return (
    <div className="search-page">
      <div className="search-page__content">
        <form className="search-page__form" onSubmit={handleSubmit}>
          <div className="search-page__form-inner">
            <legend className="search-page__legend">
              {translate("searchPage.Suche")}
            </legend>
            <label className="search-page__label" htmlFor="search">
              <input
                id="search"
                className="search-page__input"
                placeholder={translate("searchPage.placeholder")}
                onChange={handleInputChange}
                type="text"
              />
            </label>
            <div className="search-page__submit">
              <button type="submit">{translate("searchPage.searchBtn")}</button>
            </div>
          </div>
        </form>
        {loading ? (
          <Spinner />
        ) : (
          <>
            {
              // eslint-disable-next-line no-nested-ternary
              searchResults &&
              searchResults.items &&
              searchResults.items.length ? (
                <section className="search-page__results">
                  {renderResults(searchResults)}
                </section>
              ) : query ? (
                <section className="search-page__results">
                  <h5 className="search-page__no-results">
                    {translate("searchPage.no-results", query)}
                  </h5>
                </section>
              ) : null
            }
          </>
        )}
      </div>
    </div>
  );
};

export default SearchPage;
