import React, { FC, useState, useEffect } from "react";
import Filters from "../../components/Filters/Filters";
import Hero from "../../components/Hero/Hero";
import BoxItem from "../../components/BoxItem/BoxItem";
import "./OverviewPage.css";

const OverviewPage: FC<OverviewPageDto> = ({
  Images,
  IntroText,
  IntroTitle,
  Breadcrumbs,
  Delay,
  boxes,
}) => {
  const [active, setActive] = useState<string[]>([]);
  const [filteredBoxes, setFilteredBoxes] = useState<OverviewBoxDto[]>([]);
  // const { location } = useReactRouter();

  let filters: string[] = [];
  boxes.forEach((b) => {
    filters.push(...b.Filter.split(","));
  });

  filters = filters.filter((v, i, a) => a.indexOf(v) === i);

  const toggleFilter = (filter: string, force?: boolean) => {
    const activeFilter = active.indexOf(filter) >= 0;
    if (force === activeFilter) {
      return;
    }
    const activesFiltered = active.filter((f) => f !== filter);
    const newActives = activeFilter
      ? activesFiltered
      : [...activesFiltered, filter];
    setActive(newActives);
  };

  useEffect(() => {
    const newBoxes = boxes.filter(
      (b) =>
        active.length === 0 ||
        b.Filter.split(",").some((f) => active.indexOf(f) >= 0)
    );
    setFilteredBoxes(newBoxes);
  }, [active, boxes]);

  return (
    <section className="overview-page">
      <Hero
        Images={Images}
        IntroTitle={IntroTitle}
        IntroText={IntroText}
        breadcrumb={Breadcrumbs}
        Delay={Delay}
      />
      <div className="overview-page__content">
        {filters.length > 1 && (
          <div className="overview-page__filters">
            <Filters
              filters={filters.map((f) => ({ value: f, legend: f }))}
              active={active}
              toggle={toggleFilter}
            />
          </div>
        )}

        <div className="overview-page__items">
          {filteredBoxes.map((box, i) => (
            <div key={`${box.LinkURL}-${i}`} className="overview-page__item">
              <BoxItem {...box} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default OverviewPage;
