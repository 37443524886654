export default {
  AmericaRegular: {
    fontFamily: "GT-America",
    fontWeight: 400,
    fontStyle: "normal",
  },
  AmericaBold: {
    fontFamily: "GT-America",
    fontWeight: 700,
    fontStyle: "normal",
  },
  AmericaItalic: {
    fontFamily: "GT-America",
    fontWeight: 400,
    fontStyle: "italic",
  },
  AmericaItalicBold: {
    fontFamily: "GT-America",
    fontWeight: 700,
    fontStyle: "italic",
  },
};
