import React, { FC } from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';

export const ArrowWidth = 10;
const useStyles = makeStyles(() =>
  createStyles({
    root: {
      position: 'absolute',
      zIndex: 1,
      fontSize: '0.6rem',
      pointerEvents: 'initial',

      '&[data-placement="bottom"]': {
        top: ArrowWidth,
      },
      '&[data-placement="top"]': {
        bottom: '100%',
        transform: `translate(-50%, ${-ArrowWidth}px)`,
        left: '50%',
      },
    },
    content: {
      position: 'relative',
      zIndex: 2,
      background: 'white',
      padding: 12,
      borderRadius: 8,
      boxShadow: '0 2px 7px 1px rgba(0,0,0,0.3)',
      left: '50%',
      transform: 'translateX(-50%)',

    },
    figure: {
      height: 0,
      paddingBottom: '100%',
      position: 'relative',
    },
    title: {
      fontWeight: 'bold',
      padding: '15px',
      fontSize: '0.8rem',
    },
    arrow: {
      position: 'absolute',
      zIndex: 2,
      width: ArrowWidth,

      '&:before': {
        content: '""',
        display: 'block',
        left: '0',
        top: '0',
        height: '0',
        width: '100%',
        paddingBottom: '100%',
        background: 'white',
      },

      '$root[data-placement="bottom"] &': {
        top: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        '&:before': {
          boxShadow: `0 -.5px 1px rgba(0,0,0,0.5)`,
          transform: 'translate(0, -50%) rotate(-135deg)',
        }
      },

      '$root[data-placement="top"] &': {
        bottom: 0,
        left: '50%',
        transform: 'translateX(-50%) rotate(180deg)',
        '&:before': {
          boxShadow: `0 1px 2px rgba(0,0,0,0.5)`,
          transform: 'translate(0, -50%) rotate(-45deg)',
        }
      },

      '&:after': {
        position: 'absolute',
        content: '""',
        top: 0,
        left: 0,
        width: '150%',
        height: '100%',
        background: 'white',
        transform: 'scaleX(1.5)'
      },
    },
    closeBtn: {
      position: 'absolute',
      top: 2,
      right: 2,
      zIndex: 99,
      width: 15,

      '& img': {
        display: 'block',
        maxWidth: '100%',
      }
      // fontSize: 12,
      // color: 'gray',
    }
  }));

const closeIcon = "data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224px%22%20height%3D%2224px%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22%23000000%22%3E%0A%20%20%20%20%3Cpath%20d%3D%22M19%206.41L17.59%205%2012%2010.59%206.41%205%205%206.41%2010.59%2012%205%2017.59%206.41%2019%2012%2013.41%2017.59%2019%2019%2017.59%2013.41%2012z%22%2F%3E%0A%20%20%20%20%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22%2F%3E%0A%3C%2Fsvg%3E%0A";

const MapInfo: FC<{ partner: PartnerDto; placement?: 'top' | 'bottom'; toggle: (value: boolean) => void }> =
  ({ children, placement, partner: { PartnerName, Link }, toggle }) => {
    const cls = useStyles({});
    return (
      <div
        className={cls.root}
        data-placement={placement || 'top'}
      >
        <button type='button' onClick={() => toggle(false)} className={cls.closeBtn}>
          <img src={closeIcon} alt="" />
        </button>
        <div className={cls.content}>
          <div className="partners-page__marker">
            {PartnerName}
          </div>
          <a
            className="partners-page__marker-link"
            href={Link}
            target="_blank"
            rel="noreferrer noopener"
          >
            {Link}
          </a>
        </div>
        <div className={cls.arrow} />
        {children}
      </div>
    );
  }
export default MapInfo;