import { createStyles, makeStyles } from '@material-ui/styles';
import * as React from 'react';
import { FC } from 'react';
import classnames from 'classnames';
import markerImg from "./map-icon.png";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      position: 'relative',
      display: 'inline-block',
      pointerEvents: 'none',
    },
    dot: {
      display: 'block',
      width: '1rem',
      height: '1rem',
      background: 'black',
      borderRadius: '1rem',
      position: 'relative',
      cursor: 'pointer',

      '&:after': {
        content: "''",
        width: 4,
        height: 4,
        background: 'white',
        position: 'absolute',
        display: 'block',
        top: '50%',
        left: '50%',
        borderRadius: 2,
        transform: 'translate(-50%, -50%)',
      }
    },
    img: {
      pointerEvents: 'initial',
    }
  }));

type props = { lat: number; lng: number; name: string; link: string; toggle: (value: boolean) => void }
  & React.HTMLAttributes<HTMLDivElement>;
const MapMarker: FC<props> = ({ children, toggle, ...atts }) => {
  const cls = useStyles({});

  return (
    <div className={classnames(cls.root, atts.className)} >
      <img
        src={markerImg}
        alt=""
        className={cls.img}
        onClick={() => toggle(true)}
      />
      {children}
    </div>
  );
}
export default MapMarker;