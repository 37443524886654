import React, { FC, useState, useEffect, useMemo } from "react";
import "./PartnersPage.css";
import { useI18n } from "src/@context/i18n";
import Filters from "../../components/Filters/Filters";
import Lead from "../../components/Lead/Lead";
import Map from "../../components/Map/Map";

const PartnersPage: FC<PartnersPageDto> = ({
  Partners,
  Zoom,
  IntroTitle,
  IntroText,
  Breadcrumbs,
  Coordinates,
}) => {
  const { translate } = useI18n();
  const [markers, setMarkers] = useState(Partners);
  const [actives, setActives] = useState<string[]>([]);

	const filters = useMemo(() => {
    const filters: string[] = [];
		Partners.forEach((m) => {
			if (m.PartnerType) {
				filters.push(...m.PartnerType.split(',').map((f) => f.trim()));
			}
		});
    return filters.filter((v, i, a) => a.indexOf(v) === i);
	}, [Partners]);

	const toggleFilter = (filter: string, force?: boolean) => {
		const $active = actives.indexOf(filter) >= 0;
		if (force === $active) {
			return;
		}
		const without = actives.filter((f) => f !== filter);
		const newFilters = $active ? without : [...without, filter];
		setActives(newFilters);
	};

	useEffect(() => {
		const filteredMarkers = Partners.filter(
			(b) =>
				actives.length === 0 ||
				(!!b.PartnerType &&
					b.PartnerType.split(',')
						.map((f) => f.trim())
						.some((f) => actives.indexOf(f) >= 0))
		);

		setMarkers(filteredMarkers);
	}, [actives, Partners]);

	const sortAlphabetically = ($markers: PartnerDto[]) => {
		const sortedArray = $markers.sort((a, b) => {
			if (a.PartnerName < b.PartnerName) {
				return -1;
			}
			if (a.PartnerName > b.PartnerName) {
				return 1;
			}
			return 0;
		});

		return sortedArray;
	};

	const sortedMarkers = sortAlphabetically(markers);

	return (
		<div className='partners-page'>
			<div className='partners-page__content'>
				<div className='partners-page__map'>
					<Map partners={markers} zoom={Zoom} center={Coordinates} />
				</div>
				{(IntroTitle || IntroText) && (
					<div className='partners-page__lead'>
						<Lead
							title={IntroTitle}
							text={IntroText}
							breadcrumb={Breadcrumbs}
						/>
					</div>
				)}
				{Partners && (
					<div className='partners-page__list-outer'>
						<section className='partners-page__list'>
							<h4 className='partners-page__title'>
								{translate('partners.pages.title')}
							</h4>
							{filters.length > 0 && (
								<div className='partners-page__filters'>
									<Filters
										filters={filters.map((f) => ({
											legend: translate(f),
											value: f,
										}))}
										active={actives}
										toggle={toggleFilter}
									/>
								</div>
							)}
							<ul className='partners-page__list-inner'>
								{sortedMarkers.map((partner, i) => (
									<li key={i} className='partners-page__list-item'>
										{partner.Link && (
											<a
												className='partners-page__partner'
												href={partner.Link}
												target='_blank'
												rel='noreferrer noopener'
											>
												{partner.PartnerName}
											</a>
										)}

										{!partner.Link && (
											<div className='partners-page__partner'>
												{partner.PartnerName}
											</div>
										)}
									</li>
								))}
							</ul>
						</section>
					</div>
				)}
			</div>
		</div>
	);
};

export default PartnersPage;
