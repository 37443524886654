export const bodyFontSize = {
  Mobile: 13,
  Tablet: 15,
  Laptop: 18,
  Desktop: 21,
};
export default {
  Gutter: {
    Mobile: 18 / bodyFontSize.Mobile,
    Tablet: 20 / bodyFontSize.Tablet,
    Laptop: 38 / bodyFontSize.Laptop,
    Desktop: 38 / bodyFontSize.Desktop,
  },
  InfoPanelGutter: {
    Mobile: 36 / bodyFontSize.Mobile,
    Tablet: 36 / bodyFontSize.Tablet,
    Laptop: 36 / bodyFontSize.Laptop,
    Desktop: 36 / bodyFontSize.Desktop,
  },
  VerticalGutterMobile: {
    Small: 20 / bodyFontSize.Mobile,
    Medium: 35 / bodyFontSize.Mobile,
    Big: 45 / bodyFontSize.Mobile,
  },
  VerticalGutterTablet: {
    Small: 20 / bodyFontSize.Tablet,
    Medium: 35 / bodyFontSize.Tablet,
    Big: 45 / bodyFontSize.Tablet,
  },
  VerticalGutterDesktop: {
    Small: 20 / bodyFontSize.Desktop,
    Medium: 35 / bodyFontSize.Desktop,
    Big: 45 / bodyFontSize.Desktop,
  },
  ContentSidePadding: {
    Mobile: 18 / bodyFontSize.Mobile,
    Tablet: 18 / bodyFontSize.Tablet,
    Laptop: 18 / bodyFontSize.Laptop,
    Desktop: 18 / bodyFontSize.Desktop,
  },
  MaxWidthWebsite: 1800,
  MaxWidthContent: 1240,
  MaxWidthContentSmall: 760,
  MaxWidthContentIntermediate: 970,
  MaxWidthMenu: {
    Tablet: 600,
    Laptop: 800,
    Desktop: 1010,
  },
};
