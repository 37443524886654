import * as React from "react";
import "./VideoPanel.css";
import { FC } from "react";

const VideoPanel: FC<VideoPanelDto> = ({ Video, Legend }) => {
  const service = Video.Provider;
  const videoID = Video.Id;
  const youtubeSrc = `https://www.youtube.com/embed/${videoID}?modestbranding=true&autohide=1&rel=0&showinfo=0`;
  const vimeoSrc = `https://player.vimeo.com/video/${videoID}?byline=0&portrait=0&title=0&portrait=0&badge=0`;

  return (
    <div className="video-panel">
      <div className="video-panel__inner">
        <div className="video-panel__video">
          {service === "youtube" && (
            <iframe allowFullScreen src={youtubeSrc} title={videoID} />
          )}
          {service === "vimeo" && (
            <iframe allowFullScreen src={vimeoSrc} title={videoID} />
          )}
        </div>
        {Legend && (
          <div
            className="video-panel__legend"
            dangerouslySetInnerHTML={{ __html: Legend }}
          />
        )}
      </div>
    </div>
  );
};

export default VideoPanel;
