import mediaQueries from "./mediaQueries";
import fonts from "./fonts";
import { bodyFontSize } from "./variables";

// export const bodyFontSize = {
//   Mobile: 13,
//   Tablet: 15,
//   Laptop: 18,
//   Desktop: 21,
// };

export const primaryColor = `#c8316c`;

export default {
  Text: {
    ...fonts.AmericaRegular,
    lineHeight: "1.25",
    [mediaQueries.Mobile]: {
      fontSize: bodyFontSize.Mobile,
    },
    [mediaQueries.Tablet]: {
      fontSize: bodyFontSize.Tablet,
    },
    [mediaQueries.Laptop]: {
      fontSize: bodyFontSize.Laptop,
    },
    [mediaQueries.Desktop]: {
      fontSize: bodyFontSize.Desktop,
    },
  },
  MainTitle: {
    // fontFamily: "GT-America",
    // fontWeight: 600,
    ...fonts.AmericaBold,
    [mediaQueries.Mobile]: {
      fontSize: `${26 / bodyFontSize.Mobile}rem`,
    },
    [mediaQueries.Tablet]: {
      fontSize: `${42 / bodyFontSize.Tablet}rem`,
    },
    [mediaQueries.Laptop]: {
      fontSize: `${42 / bodyFontSize.Laptop}rem`,
    },
    [mediaQueries.Desktop]: {
      fontSize: `${52 / bodyFontSize.Desktop}rem`,
    },
  },
  SecondaryTitle: {
    ...fonts.AmericaBold,
    [mediaQueries.Mobile]: {
      fontSize: `${21 / bodyFontSize.Mobile}rem`,
    },
    [mediaQueries.Tablet]: {
      fontSize: `${26 / bodyFontSize.Tablet}rem`,
    },
    [mediaQueries.Laptop]: {
      fontSize: `${26 / bodyFontSize.Laptop}rem`,
    },
    [mediaQueries.Desktop]: {
      fontSize: `${34 / bodyFontSize.Desktop}rem`,
    },
  },
  TertiaryTitle: {
    ...fonts.AmericaBold,
    [mediaQueries.Mobile]: {
      fontSize: `${18 / bodyFontSize.Mobile}rem`,
    },
    [mediaQueries.Tablet]: {
      fontSize: `${18 / bodyFontSize.Tablet}rem`,
    },
    [mediaQueries.Laptop]: {
      fontSize: `${18 / bodyFontSize.Laptop}rem`,
    },
    [mediaQueries.Desktop]: {
      fontSize: `${21 / bodyFontSize.Desktop}rem`,
    },
  },
  Lead: {
    ...fonts.AmericaRegular,
    [mediaQueries.Mobile]: {
      fontSize: `${24 / bodyFontSize.Mobile}rem`,
    },
    [mediaQueries.Tablet]: {
      fontSize: `${24 / bodyFontSize.Tablet}rem`,
    },
    [mediaQueries.Laptop]: {
      fontSize: `${24 / bodyFontSize.Laptop}rem`,
    },
    [mediaQueries.Desktop]: {
      fontSize: `${28 / bodyFontSize.Desktop}rem`,
    },
  },
  Menu: {
    ...fonts.AmericaBold,
    lineHeight: 1,
    [mediaQueries.Mobile]: {
      fontSize: `${28 / bodyFontSize.Mobile}rem`,
    },
    [mediaQueries.Tablet]: {
      fontSize: `${21 / bodyFontSize.Tablet}rem`,
    },
    [mediaQueries.Laptop]: {
      fontSize: `${26 / bodyFontSize.Laptop}rem`,
    },
    [mediaQueries.Desktop]: {
      fontSize: `${32 / bodyFontSize.Desktop}rem`,
    },
  },
  Breadcrumb: {
    ...fonts.AmericaRegular,
    lineHeight: 1,
    [mediaQueries.Mobile]: {
      fontSize: `${14 / bodyFontSize.Mobile}rem`,
    },
    [mediaQueries.Tablet]: {
      fontSize: `${14 / bodyFontSize.Tablet}rem`,
    },
    [mediaQueries.Laptop]: {
      fontSize: `${14 / bodyFontSize.Laptop}rem`,
    },
    [mediaQueries.Desktop]: {
      fontSize: `${14 / bodyFontSize.Desktop}rem`,
    },
  },
  FilterFooter: {
    [mediaQueries.Mobile]: {
      fontSize: `${14 / bodyFontSize.Mobile}rem`,
    },
    [mediaQueries.Tablet]: {
      fontSize: `${16 / bodyFontSize.Tablet}rem`,
    },
    [mediaQueries.Laptop]: {
      fontSize: `${18 / bodyFontSize.Laptop}rem`,
    },
    [mediaQueries.Desktop]: {
      fontSize: `${18 / bodyFontSize.Desktop}rem`,
    },
  },
  ExternalLink: {
    "&:before": {
      content: '"↗"',
      display: "inline-block",
      marginRight: 5,

      fontFeatureSettings: '"ss03"',
    },
  },
  ExternalLinkAfter: {
    "&:before": {
      content: '"↗"',
      display: "inline-block",
      marginLeft: 5,

      fontFeatureSettings: '"ss03"',
    },
  },
  InternalLink: {
    content: '"→"',
    display: "inline-block",
    marginRight: 5,

    fontFeatureSettings: '"ss03"',
  },
  InternalLinkBefore: {
    "&:before": {
      content: '"→"',
      display: "inline-block",
      marginRight: 5,

      fontFeatureSettings: '"ss03"',
    },
  },
  InternalLinkAfter: {
    "&:before": {
      content: '"→"',
      display: "inline-block",
      marginLeft: 5,

      fontFeatureSettings: '"ss03"',
    },
  },
  ArrowLeftBefore: {
    "&:before": {
      content: '"←"',
      display: "inline-block",
      marginRight: 5,

      fontFeatureSettings: '"ss03"',
    },
  },
  ArrowRightAfter: {
    "&:before": {
      content: '"→"',
      display: "inline-block",
      marginLeft: 5,

      fontFeatureSettings: '"ss03"',
    },
  },
  ArrowTop: {
    "&:before": {
      content: '"↑"',
      padding: "0 5px",

      fontFeatureSettings: '"ss03"',
    },
  },
  ArrowBootom: {
    "&:before": {
      content: '"↓"',
      padding: "0 5px",

      fontFeatureSettings: '"ss03"',
    },
  },
  ObjectFitCover: {
    objectFit: "cover",
    fontFamily: '"object-fit: cover;"',
  },
  ObjectFitContain: {
    objectFit: "contain",
    fontFamily: '"object-fit: contain;"',
  },
  boxShadow: {
    boxShadow: "4px 4px 3px rgba(0, 0, 0, .3)",
  },
};
