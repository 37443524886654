import React, { FC, useRef, useState } from "react";
import "./ImagesPanel.css";
import Flickity from "react-flickity-component";
import isTouchDevice from "is-touch-device";
import classNames from "classnames";
import arrow from "../../assets/slider-arrow.svg";
import { Image } from "../Image";
import { ClassNames } from "../../utils/ClassNames";

const ImagesPanel: FC<ImagesPanelDto> = ({ Images }) => {
  const flickity = useRef<any>(null);
  const [current, setCurrent] = useState(0);

  const images = Images?.filter((i) => !!i) || [];
  const count = images.length;
  React.useEffect(() => {
    const instance = flickity.current;
    if (!instance) {
      return;
    }
    const change = (index: number) => {
      setCurrent(index % count);
    };

    instance.on("change", change);

    // eslint-disable-next-line consistent-return
    return () => instance.off("change", change);
  }, [count, flickity]);

  if (Images == null) {
    return null;
  }

  const next = () => flickity.current && flickity.current.next();
  const previous = () => flickity.current && flickity.current.previous();
  const hasLegends = Images.some((img) => !!img.Legend);

  const sizes = "(max-width: 969px) 100w, 1000px";

  return (
    <div className="images-panel">
      <div className="images-panel__inner">
        <div className="images-panel__slider">
          <div className="images-panel__slider-inner">
            <Flickity
              className="hero__container" // default ''
              options={{
                pageDots: false,
                prevNextButtons: false,
                setGallerySize: false,
                wrapAround: true,
                accessibility: false,
                adaptiveHeight: false,
                draggable: isTouchDevice(),
              }}
              disableImagesLoaded={false} // default false
              // eslint-disable-next-line no-return-assign
              flickityRef={(r) => (flickity.current = r)}
            >
              {Images.map((s) => (
                <div key={s.Id} className="hero__slider-figure">
                  <Image
                    className="images-panel__slider-image"
                    pictureRef={s}
                    alt=""
                    sizes={sizes}
                  />
                </div>
              ))}
            </Flickity>
          </div>
          {Images.length > 1 && (
            <nav className="images-panel__slider-nav">
              <button
                type="button"
                aria-label="nextSlide"
                className={classNames(
                  "images-panel__slider-btn",
                  "images-panel__slider-btn--next"
                )}
                onClick={() => next()}
              >
                <img src={arrow} alt="" />
              </button>
              <button
                type="button"
                aria-label="previousSlide"
                className={classNames(
                  "images-panel__slider-btn",
                  "images-panel__slider-btn--previous"
                )}
                onClick={() => previous()}
              >
                <img src={arrow} alt="" />
              </button>
            </nav>
          )}
        </div>
        {hasLegends && (
          <div className="images-panel__slider-legends">
            {images.map((image, i) => {
              const active = current === i;
              return (
                <div
                  key={i}
                  className={ClassNames(
                    "images-panel",
                    "slider-legend",
                    active && "active"
                  )}
                >
                  <div className="images-panel__slider-legend-inner">
                    {image.Legend}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default ImagesPanel;
