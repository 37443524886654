import React, { FC } from "react";
import "./PanelsPage.css";
import Hero from "../../components/Hero/Hero";
import Panel from "../../components/Panel";

const PanelsPage: FC<PanelsPageDto> = ({
  Images,
  IntroTitle,
  IntroText,
  Breadcrumbs,
  Delay,
  Panels,
}) => {
  return (
    <div className="panel-page">
      <Hero
        Images={Images}
        IntroTitle={IntroTitle}
        IntroText={IntroText}
        breadcrumb={Breadcrumbs}
        Delay={Delay}
      />
      <div className="panel-page__content">
        {Panels && (
          <div className="panel-page__sections">
            {Panels.map((panel, i) => {
              switch (panel.type) {
                case "AccordionPanel":
                  return (
                    <div
                      key={i}
                      className="panel-page__section panel-page__section--accordion"
                    >
                      <Panel {...(panel as AccordionPanelDto)} />
                    </div>
                  );
                case "ParagraphPanel":
                  return (
                    <div
                      key={i}
                      className="panel-page__section panel-page__section--paragraph"
                    >
                      <Panel {...(panel as ParagraphPanelDto)} />
                    </div>
                  );
                case "InfosPanel":
                  return (
                    <div
                      key={i}
                      className="panel-page__section panel-page__section--infos"
                    >
                      <Panel {...(panel as InfosPanelDto)} />
                    </div>
                  );
                case "ImagesPanel":
                  return (
                    <div
                      key={i}
                      className="panel-page__section panel-page__section--images"
                    >
                      <Panel {...(panel as ImagesPanelDto)} />
                    </div>
                  );
                case "VideoPanel":
                  return (
                    <div
                      key={i}
                      className="panel-page__section panel-page__section--video"
                    >
                      <Panel {...(panel as VideoPanelDto)} />
                    </div>
                  );
                case "PartnersPanel":
                  return (
                    <div
                      key={i}
                      className="panel-page__section panel-page__section--partners"
                    >
                      <Panel {...(panel as PartnersPanelDto)} />
                    </div>
                  );
                case "SupportsPanel":
                  return (
                    <div
                      key={i}
                      className="panel-page__section panel-page__section--supports"
                    >
                      <Panel {...(panel as SupportsPanelDto)} />
                    </div>
                  );

                default:
                  return null;
              }
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default PanelsPage;
