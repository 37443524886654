/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  RefObject,
  useCallback,
  useEffect as useLayoutEffect,
  useState,
} from "react";

const useElementSize = (ref: RefObject<HTMLElement>) => {
  const [elementSize, setElementSize] = useState({ width: 1, height: 1 });

  const getAndSetElementSize = useCallback(() => {
    const rect = ref.current?.getBoundingClientRect();
    if (!rect) {
      return;
    }

    const { top, right, bottom, left } = rect;
    const width = right - left;
    const height = bottom - top;
    setElementSize({ width, height });
  }, []);

  const recalculate = useCallback(() => {
    getAndSetElementSize();
  }, []);

  useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }

    const resizeObserver = new ResizeObserver((/* entries */) => {
      getAndSetElementSize();
    });

    getAndSetElementSize();

    const element = ref.current;
    resizeObserver.observe(element);
    window.addEventListener("resize", getAndSetElementSize);

    // eslint-disable-next-line consistent-return
    return () => {
      window.removeEventListener("resize", getAndSetElementSize);
      // eslint-disable-next-line no-unused-expressions
      resizeObserver?.unobserve(element);
    };
  }, [getAndSetElementSize, ref]);

  return { ...elementSize, recalculate };
};

export default useElementSize;
