import React, { useEffect, useCallback, FC } from 'react';
import { makeStyles, createStyles } from '@material-ui/styles';
import * as ReactGA from 'react-ga';
import DocumentMeta from 'react-document-meta';
import useReactRouter from 'use-react-router';
import classNames from 'classnames';
import { useApi } from './@context/Api';
import { useApp } from './@context/App';
import StartPage from './Pages/StartPage/StartPage';
import OverviewPage from './Pages/OverviewPage/OverviewPage';
import Footer from './components/Footer/Footer';
import { bodyFontSize } from './constants/variables';
import mediaQueries from './constants/mediaQueries';
import PanelsPage from './Pages/PanelsPage/PanelsPage';
import KontaktPage from './Pages/KontaktPage/KontaktPage';
import ImpressumPage from './Pages/ImpressumPage/ImpressumPage';
import PartnersPage from './Pages/PartnersPage/PartnersPage';
import NewsletterPage from './Pages/NewsletterPage/NewsletterPage';
import SearchPage from './Pages/SearchPage/SearchPage';
import Error404 from './Pages/Error404/Error404';
import Spinner from './components/Spinner/Spinner';
import Cookies from './components/Cookies';

const topPaddingMobile = `${20 / bodyFontSize.Mobile}rem`;
const topPaddingTablet = `${20 / bodyFontSize.Tablet}rem`;
const topPaddingLaptop = `${48 / bodyFontSize.Laptop}rem`;
const topPaddingDesktop = `${48 / bodyFontSize.Desktop}rem`;

const addingStartpageMobile = `${20 / bodyFontSize.Mobile}rem`;
const addingStartpageTablet = `${20 / bodyFontSize.Tablet}rem`;
const addingStartpageLaptop = `${130 / bodyFontSize.Laptop}rem`;
const addingStartpageDesktop = `${155 / bodyFontSize.Desktop}rem`;

const footerSpacingMobile = 80;
const footerSpacingTablet = 150;
const footerSpacingLaptop = 245;
const footerSpacingDesktop = 245;

const useStyles = makeStyles(() =>
	createStyles({
		loadingContainer: {
			position: 'absolute',
			transition: 'opacity 0.2s',
			pointerEvents: 'none',
			top: '50vh',
			left: '50vw',
			transform: 'translate(-50%,-50%)',
		},
		loadingText: {
			// ...stylesheet.BoldText,
		},
		pages: {
			boxSizing: 'border-box',

			[mediaQueries.Mobile]: {
				paddingTop: topPaddingMobile,
				marginBottom: footerSpacingMobile,
			},
			[mediaQueries.Tablet]: {
				paddingTop: topPaddingTablet,
				marginBottom: footerSpacingTablet,
			},
			[mediaQueries.Laptop]: {
				paddingTop: topPaddingLaptop,
				marginBottom: footerSpacingLaptop,
			},
			[mediaQueries.Desktop]: {
				paddingTop: topPaddingDesktop,
				marginBottom: footerSpacingDesktop,
			},
		},
		startpage: {
			[mediaQueries.Mobile]: {
				paddingTop: addingStartpageMobile,
			},
			[mediaQueries.Tablet]: {
				paddingTop: addingStartpageTablet,
			},
			[mediaQueries.Laptop]: {
				paddingTop: addingStartpageLaptop,
			},
			[mediaQueries.Desktop]: {
				paddingTop: addingStartpageDesktop,
			},
		},
	})
);

const Pages: FC = () => {
	const cls = useStyles();
	const {
		location: { pathname },
	} = useReactRouter();
	const { get } = useApi();
	const { app } = useApp();
	const [page, setPage] = React.useState<IPageDto>();

	const [, setFetching] = React.useState(false);

	const scrollElm = document.scrollingElement;
	React.useEffect(() => {
		if (!scrollElm) {
			return;
		}

		if (scrollElm.scrollTop > 0) {
			scrollElm.scrollTop = 0;
		}
	}, [page, scrollElm]);

	const fetchPage = useCallback(
		async (endpoint: string) => {
			setFetching(true);
			const pageDto = await get(endpoint);
			setPage(pageDto);
			setFetching(false);
		},
		[get]
	);

	useEffect(() => {
		fetchPage(pathname);
	}, [fetchPage, get, pathname]);

	React.useEffect(() => {
		if (!app || !app.Config) {
			return;
		}
		const config = app.Config;

		if (!config.GoogleAnalyticsCode) {
			return;
		}

		ReactGA.initialize(`${config.GoogleAnalyticsCode}`);
		ReactGA.pageview(window.location.pathname);
	}, [page, app]);

	if (!page || !app) {
		return <Spinner />;
	}

	return (
		<>
			<DocumentMeta
				title={page.PageTitle || app.Config.WebsiteTitle || ''}
				description={page.Description || app.Config.GoogleDescription || ''}
			/>

			<Cookies />

			<div
				className={classNames(
					cls.pages,
					page.type === 'StartPage' && cls.startpage
				)}
			>
				{page.type === 'StartPage' && <StartPage {...(page as StartPageDto)} />}
				{page.type === 'OverviewPage' && (
					<OverviewPage {...(page as OverviewPageDto)} />
				)}
				{page.type === 'PanelsPage' && (
					<PanelsPage {...(page as PanelsPageDto)} />
				)}
				{page.type === 'KontaktPage' && (
					<KontaktPage {...(page as KontaktPageDto)} />
				)}
				{page.type === 'ImpressumPage' && (
					<ImpressumPage {...(page as ImpressumPageDto)} />
				)}
				{page.type === 'PartnersPage' && (
					<PartnersPage {...(page as PartnersPageDto)} />
				)}
				{page.type === 'NewsletterPage' && (
					<NewsletterPage {...(page as NewsletterPageDto)} />
				)}
				{page.type === 'SearchPage' && (
					<SearchPage {...(page as SearchPageDto)} />
				)}
				{page.type === 'Error404' && <Error404 {...(page as Error404Dto)} />}
			</div>
			<Footer />
		</>
	);
};

export default Pages;
