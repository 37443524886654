import * as React from 'react';
import './MenuMobile.css';
import { Link } from 'react-router-dom';
import { FC } from 'react';
import { NextLanguage, Language } from 'src/utils/Languages';
import { useI18n } from '../../@context/i18n';
import MenuMobileItem from './MenuMobileItem';
import { useApp } from '../../@context/App';

export type MenuMobileProps = {
	setMenuOpen: (click: boolean) => void;
};

const MenuMobile: FC<MenuMobileProps> = ({ setMenuOpen }) => {
	const { app } = useApp();
	const { translate } = useI18n();

	if (!app) {
		return null;
	}
	const menus = app.Menus;

	return (
		<nav className='menu-mobile'>
			<div className='menu-mobile__container'>
				<div className='menu-mobile__primary'>
					{menus.menuItems.map((menu) => {
						return (
							<MenuMobileItem
								menu={menu}
								key={menu.uid}
								setMenuOpen={(click) => setMenuOpen(click)}
							/>
						);
					})}
				</div>
				<div className='menu-mobile__secondary'>
					<a
						className='menu-mobile__secondary-link menu-mobile__secondary-link--lang'
						href={`?switch-lang=${NextLanguage}`}
					>
						{NextLanguage}
					</a>
					{menus.secondaryItems.map((menuItem) => (
						<Link
							key={menuItem.uid}
							to={menuItem.url}
							className='menu-mobile__secondary-link'
							onClick={() => setMenuOpen(false)}
						>
							{menuItem.title}
						</Link>
					))}
					<Link
						to={`/${Language}/suche`}
						className='menu-mobile__secondary-link'
					>
						{translate('menu.suche')}
					</Link>
				</div>
				<div className='menu-mobile__social'>
					{menus.social.map((l) => (
						<a
							key={l.href}
							href={l.href}
							className='menu-mobile__social-link'
							onClick={() => setMenuOpen(false)}
						>
							{l.title}
						</a>
					))}
					{menus.Newsletter && (
						<Link
							to={menus.Newsletter.url}
							className='menu-mobile__newsletter-link'
							onClick={() => setMenuOpen(false)}
						>
							{menus.Newsletter.title}
						</Link>
					)}
				</div>
			</div>
		</nav>
	);
};
export default MenuMobile;
