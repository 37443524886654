import { createStyles, makeStyles } from '@material-ui/styles';
import * as React from 'react';
import { FC } from 'react';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      position: 'relative',
      color: 'white',
      display: 'inline-block',
      padding: '0.5rem',
      cursor: 'pointer',
      // ...stylesheet.RegularText,
      fontSize: 14,

      '&:after': {
        top: '50%',
        left: '50%',
        width: '1.5rem',
        height: 0,
        content: "''",
        display: 'block',
        position: 'absolute',
        background: 'black',
        paddingBottom: '1.5rem',

        zIndex: -1,
        transform: 'translate(-50%, -55%)',
        borderRadius: '1rem',
      }
    },
  }));

const MapCluster: FC<{ lat: number; lng: number; triggers: () => void; }> = ({ children, triggers }) => {
  const cls = useStyles({});

  return (
    <div
      className={cls.root}
      onClick={triggers}
      tabIndex={-1}
      onKeyUp={triggers}
      role="button"
    >
      {children}
    </div>
  );
}
export default MapCluster;