import React, { FC } from "react";
import { Link } from "react-router-dom";
import { useApp } from "../../@context/App";
import "./Footer.css";

const Footer: FC = () => {
  const { app } = useApp();

  if (!app) {
    return null;
  }

  const { Menus } = app;

  return (
    <footer className="footer">
      <div className="footer__inner">
        <div className="footer__content-desktop">
          <div className="footer__title">Reso – Tanznetzwerk Schweiz</div>
          <div className="footer__address">Laupenstrasse 5a, 3008 Bern</div>
          <a href="mailto:info@reso.ch" className="footer__mail">
            info@reso.ch
          </a>
          <div className="footer__copyright">© Reso 2018</div>
          {Menus.Impressum && (
            <Link to={Menus.Impressum.url} className="footer__download-link">
              {Menus.Impressum.title}
            </Link>
          )}
        </div>
        <div className="footer__content-mobile">
          <div className="footer__column">
            <div className="footer__title">Reso – Tanznetzwerk Schweiz</div>
            <div className="footer__address">Laupenstrasse 5a, 3008 Bern</div>
            <a href="mailto:info@reso.ch" className="footer__mail">
              info@reso.ch
            </a>
          </div>
          <div className="footer__column">
            <div className="footer__copyright">© Reso 2018</div>
            {Menus.Impressum && (
              <Link to={Menus.Impressum.url} className="footer__download-link">
                {Menus.Impressum.title}
              </Link>
            )}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
