import React, { FC, useEffect, useState } from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import { useApp } from "../@context/App";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      position: "fixed",
      top: "50vh",
      width: "100%",
      left: 0,
      transform: "translateY(-50%)",

      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",

      padding: "20px 30px",
      backgroundColor: "rgba(0, 255, 255, .9)",
      zIndex: 9,
      fontSize: 20,
    },
  })
);

const Cookies: FC = () => {
  const cls = useStyles();
  const { app } = useApp();
  const [show, setShow] = useState<boolean>();

  useEffect(() => {
    const cookie = localStorage.getItem("showCookies");
    setShow(cookie !== "false");
  }, [show]);

  const handleClick = () => {
    localStorage.setItem("showCookies", "false");
    setShow(false);
  };

  if (!app || !app.Config || !show) {
    return null;
  }

  return (
    <div className={cls.root}>
      <div
        style={{
          display: "inline-block",
          marginRight: 5,
        }}
        dangerouslySetInnerHTML={{ __html: app.Config.CookieText }}
      />
      <button
        onClick={handleClick}
        type="button"
        style={{
          backgroundColor: "transparent",
          border: "none",
          margin: 0,
          padding: 0,
          fontFamily: "inherit",
          fontSize: 20,
        }}
      >
        [ ok ]
      </button>
    </div>
  );
};

export default Cookies;
