import React, { FC } from "react";
import "./SupportsPanel.css";
import { ClassNames } from "../../utils/ClassNames";
import { Image } from "../../components/Image";

const SupportsPanel: FC<SupportsPanelDto> = ({ Title, SupportImages }) => {
  const sizes = "(max-width: 767px) 100w, 260px";

  return (
    <div className="supports-panel">
      {Title && <h4 className="supports-panel__title">{Title}</h4>}
      <div className="supports-panel__items">
        {SupportImages &&
          SupportImages.map((img, i) => (
            <div key={i} className={ClassNames("supports-panel", "item")}>
              <a
                className="supports-panel__item-inner"
                href={img.Link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  pictureRef={img.Image}
                  className="supports-panel__image"
                  alt={img.Title}
                  sizes={sizes}
                />
              </a>
            </div>
          ))}
      </div>
    </div>
  );
};

export default SupportsPanel;
