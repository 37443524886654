import React, { FC } from "react";
import "./Error404.css";
import Lead from "../../components/Lead/Lead";

const Error404: FC<Error404Dto> = ({ Title, Text }) => {
  return (
    <div className="error-404">
      <div className="error-404__content">
        <div className="error-404__lead">
          <Lead title={Title} text={Text} />
        </div>
      </div>
    </div>
  );
};

export default Error404;
