import React, { FC } from "react";
import "./KontaktPage.css";
import { ClassNames } from "../../utils/ClassNames";
import Hero from "../../components/Hero/Hero";

const KontaktPage: FC<KontaktPageDto> = ({
  Images,
  IntroTitle,
  IntroText,
  Breadcrumbs,
  TextPanels,
}) => {
  return (
    <div className="kontakt">
      <div className="kontakt__content">
        <Hero
          Images={Images}
          IntroTitle={IntroTitle}
          IntroText={IntroText}
          breadcrumb={Breadcrumbs}
        />
        {TextPanels && (
          <div className="kontakt__panels">
            <div className="kontakt__panels-inner">
              {TextPanels.map((panel, i) => (
                <div
                  key={`${panel}-${i}`}
                  className={ClassNames(
                    "kontakt",
                    "panel",
                    panel.Size === "Half" && "half"
                  )}
                  dangerouslySetInnerHTML={{ __html: panel.Text }}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default KontaktPage;
