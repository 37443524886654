import * as React from "react";
import "./Filters.css";
import { FC } from "react";
import { ClassNames } from "../../utils/ClassNames";
import { useI18n } from "../../@context/i18n";

export type filtersProps = {
  filters: Array<{ value: string; legend: string }>;
  active: string[];
  toggle: (filter: string, force?: boolean) => void;
};

const Filters: FC<filtersProps> = ({ filters, active: $active, toggle }) => {
  const { translate } = useI18n();

  return (
    <div className="filters">
      <div className="filters__prefix">{translate("filter.title")}</div>
      {filters && filters.length > 0 && (
        <div className="filters__group">
          {filters.map((f, i) => {
            const active = $active.indexOf(f.value) >= 0;
            return (
              <button
                type="button"
                className={ClassNames("filters", "link", active && "active")}
                key={`${f}-${i}`}
                onClick={() => toggle(f.value, !active)}
              >
                {f.legend}
                {active && (
                  <div role="button" className="filters__link-remove">
                    x
                  </div>
                )}
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Filters;
