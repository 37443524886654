import objectFitImages from "object-fit-images";
import * as React from "react";
import { forwardRef, ImgHTMLAttributes } from "react";
import cdn from "src/constants/cdn";

export type ImageProps = ImgHTMLAttributes<HTMLImageElement> & {
  pictureRef?: PictureRef;
  format?: string;
};

const getSrc = (name: string, formatName: string) => {
  const array = name.split("");
  const i = array.lastIndexOf("/");
  array.splice(i, 0, `/${formatName}`);
  return `${cdn.images.url}/${array.join("")}`;
};

// eslint-disable-next-line react/display-name
export const Image = forwardRef((props: ImageProps, ref) => {
  React.useEffect(() => {
    // TODO: need to call the plyfill on every image ?
    objectFitImages();
  }, []);

  const {
    alt,
    children,
    pictureRef,
    src,
    format,
    sizes: propSizes,
    ...rest
  } = props;
  // => depend on the browser / /test webp here
  const sizes = propSizes || "100vw";
  const width = pictureRef?.Width || 0;
  const imageSrc =
    (pictureRef && `${cdn.images.url}/${pictureRef?.Name}`) || src || "";
  const name = pictureRef?.Name;
  const srcSetArr: string[] = [];
  const { formats } = cdn.images;

  if (name && formats) {
    const currentFormat = formats[format || "default"];

    // eslint-disable-next-line no-restricted-syntax
    for (const { name: formatName, width: formatWidth } of currentFormat) {
      if (width && width <= formatWidth) {
        // eslint-disable-next-line no-continue
        continue;
      }
      const finalSrc = getSrc(name, formatName);
      srcSetArr.push(`${finalSrc} ${formatWidth}w`);
    }
  }

  // tslint:disable-next-line:no-console
  return (
    <>
      <img
        {...{
          alt: alt || "",
          srcSet: srcSetArr.join(", "),
          sizes,
          ref: (ref as unknown) as any,
          src: `${imageSrc}`,
          ...rest,
        }}
      />
      {children}
    </>
  );
});

export default Image;
