import React, { useRef, useState, FC } from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import GoogleMapReact from 'google-map-react';
import Supercluster from 'supercluster';
import { BBox } from 'geojson';
import useSupercluster from 'use-supercluster';
import MapCluster from './Map-cluster';
import MapMarker from './Map-marker';
import MapInfo from './Map-info';
import mapStyles from './mapStyles.json';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
  })
);

const MAP = {
  options: {
    styles: mapStyles,
    minZoom: 0,
    // maxZoom: 19,
  },
  mobile: {
    mapTypeId: 'roadmap',
    mapTypeControl: false,
    mapTypeControlOptions: {
      mapTypeIds: ['roadmap'],
    },
  },
  afterMobile: {
    mapTypeId: 'roadmap',
    mapTypeControl: true,
    mapTypeControlOptions: {
      mapTypeIds: ['roadmap', 'satellite'],
    },
  },
};

export type MapProps = {
  partners: PartnerDto[];
  center?: GeoPoint;
  zoom: number;
};
type TPoint = Supercluster.PointFeature<Supercluster.AnyProps>;

const Map: FC<MapProps> = ({ partners, center, zoom: initZoom, children }) => {
  const cls = useStyles();
  const mapRef = useRef<any>();
  const [bounds, setBounds] = useState<BBox>();
  const [zoom, setZoom] = useState(0);
  const [openedInfo, setOpenedInfo] = useState<string>('');

  const points: TPoint[] = partners
    .filter((p) => p.Coordinates)
    .map((p, i) => ({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [p.Coordinates.lng, p.Coordinates.lat],
      },
      properties: {
        id: `partner-${p.PartnerName}-${i}`,
        partner: p,
      },
    }));

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 75, maxZoom: 20 },
  });

  const onGoogleApiLoaded = ({ map }: any) => {
    mapRef.current = map;
  };

  const onMapChange = ({ zoom: $zoom, bounds: { nw, se } }: any) => {
    setZoom($zoom);
    setBounds([nw.lng, se.lat, se.lng, nw.lat]);
  };

  return (
    <div className={cls.container}>
      <GoogleMapReact
        distanceToMouse={() => 0}
        defaultZoom={initZoom}
        defaultCenter={center}
        options={{ ...MAP.options }}
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GOOGLE_KEY || '',
        }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={onGoogleApiLoaded}
        onChange={onMapChange}
      >
        {clusters.map((cluster) => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const {
            cluster: isCluster,
            point_count: pointCount,
            partner,
          } = cluster.properties;

          if (isCluster) {
            const zoomIn = () => {
              if (!supercluster) {
                return;
              }
              const expansionZoom = Math.min(
                supercluster.getClusterExpansionZoom(cluster.id as number),
                20
              );
              mapRef.current.setZoom(expansionZoom);
              mapRef.current.panTo({ lat: latitude, lng: longitude });
            };

            return (
              <MapCluster
                key={`cluster-${cluster.id}`}
                lat={latitude}
                lng={longitude}
                triggers={zoomIn}
              >
                {pointCount}
              </MapCluster>
            );
          }
          const { id } = cluster.properties;
          const toggle = (value: boolean) => {
            setOpenedInfo(value ? id : '');
          };
          const opened = openedInfo === id;
          return (
            <MapMarker
              key={`project-${id}`}
              lat={latitude}
              lng={longitude}
              toggle={toggle}
              link={partner.Link}
              name={partner.NamePartner}
              style={{ zIndex: (opened && 99) || undefined }}
            >
              {(opened && <MapInfo partner={partner} toggle={toggle} />) ||
                null}
            </MapMarker>
          );
        })}
        {children}
      </GoogleMapReact>
    </div>
  );
};

export default Map;
