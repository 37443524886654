import React, { FC } from 'react';
import { makeStyles, createStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import useReactRouter from 'use-react-router';
import mediaQueries from '../constants/mediaQueries';
import stylesheet from '../constants/stylesheet';
import variables, { bodyFontSize } from '../constants/variables';

const topPaddingMenuTablet = `${18 / bodyFontSize.Tablet}rem`;
const topPaddingMenuLaptop = `${28 / bodyFontSize.Laptop}rem`;
const topPaddingMenuDesktop = `${28 / bodyFontSize.Desktop}rem`;

const bottomPaddingMenuTablet = `${10 / bodyFontSize.Tablet}rem`;
const bottomPaddingMenuLaptop = `${20 / bodyFontSize.Laptop}rem`;
const bottomPaddingMenuDesktop = `${20 / bodyFontSize.Desktop}rem`;

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    linkGroup: {
      [mediaQueries.AfterMobile]: {
        display: 'none',

        '&:first-child': {
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        },
        // "&[data-show='true']": {
        //   display: "block",
        // },
        "&[data-show='true'][data-sub-group='true']": {
          display: 'block',
        },
        "&[data-sub-group='true']": {
          transform: 'translateY(40px)',
          padding: 20,
          flexGrow: 1,
          flexShrink: 1,
          backgroundColor: 'white',
          ...stylesheet.boxShadow,
        },
      },
      [mediaQueries.TabletLandscape]: {
        "&[data-sub-group='true']": {
          padding: `${topPaddingMenuTablet}rem ${variables.Gutter.Tablet} ${bottomPaddingMenuTablet}rem ${variables.Gutter.Tablet}`,
        },
        '&:first-child': {
          padding: `0 ${variables.Gutter.Tablet}rem`,
        },
      },
      [mediaQueries.Laptop]: {
        "&[data-sub-group='true']": {
          padding: `${topPaddingMenuLaptop}rem ${variables.Gutter.Laptop} ${bottomPaddingMenuLaptop}rem ${variables.Gutter.Laptop}`,
        },
        '&:first-child': {
          padding: `0 ${variables.Gutter.Laptop}rem`,
        },
      },
      [mediaQueries.Desktop]: {
        "&[data-sub-group='true']": {
          padding: `${topPaddingMenuDesktop}rem ${variables.Gutter.Desktop} ${bottomPaddingMenuDesktop}rem ${variables.Gutter.Desktop}`,
        },
        '&:first-child': {
          padding: `0 ${variables.Gutter.Desktop}rem`,
        },
      },
    },
    linkFont: {
      ...stylesheet.Menu,
    },
    link: {
      position: 'relative',

      '[data-current="true"]&:after': {
        content: '""',
        width: '100%',
        height: 5,
        display: 'block',
        background: 'black',
        position: 'absolute',
        bottom: 0,
      },
      '[data-hover="true"]&:after': {
        content: '""',
        width: '100%',
        height: 5,
        display: 'block',
        background: 'black',
        position: 'absolute',
        bottom: 0,
      },
      "$linkGroup[data-sub-group='true'] &": {
        fontSize: '1rem',
        fontWeight: 'normal',

        paddingBottom: 0,
      },
      "$linkGroup[data-sub-group='true'] [data-current='true']&": {
        textDecoration: 'underline',
      },
      "$linkGroup[data-sub-group='true'] [data-hover='true']&": {
        textDecoration: 'underline',
      },
      "$linkGroup[data-sub-group='true'] [data-current='true']&:after": {
        content: 'none',
      },
      "$linkGroup[data-sub-group='true'] [data-hover='true']&:after": {
        content: 'none',
      },

      [mediaQueries.AfterMobile]: {
        "$linkGroup[data-sub-group='true'] &": {
          display: 'block',
        },
      },
      [mediaQueries.TabletLandscape]: {
        paddingBottom: `${bottomPaddingMenuTablet}`,

        "$linkGroup[data-sub-group='true'] &": {
          marginBottom: 10,
        },
      },
      [mediaQueries.Laptop]: {
        paddingBottom: `${bottomPaddingMenuLaptop}`,

        "$linkGroup[data-sub-group='true'] &": {
          marginBottom: 10,
        },
      },
      [mediaQueries.Desktop]: {
        paddingBottom: `${bottomPaddingMenuDesktop}`,

        "$linkGroup[data-sub-group='true'] &": {
          marginBottom: 10,
        },
        "$linkGroup[data-sub-group='true'] [data-current='true']&:after": {
          content: 'initial',
        },
      },
    },
  })
);

export type MenuItemProps = {
  menus: MenuDto[];
  // menuOpen: (opened: boolean) => void;
  menuEnter: (uid: string, depth: number) => void;
  hover: string[];
  depth: number;
  show: boolean;
};

const MenuItem: FC<MenuItemProps> = ({
  menus,
  hover,
  depth,
  show,
  menuEnter,
  // menuOpen,
}) => {
  const cls = useStyles();
  const { location } = useReactRouter();

  return (
    <>
      <div
        className={cls.linkGroup}
        data-show={show}
        data-sub-group={depth > 0}
      >
        {menus.map((child) => {
          const active = hover.length > depth && hover[depth] === child.uid;
          const current = location.pathname.indexOf(child.url) === 0;
          // const currentParent = child.url === props.location.pathname;
          return (
            <Link
              to={child.url}
              className={classNames(cls.link, cls.linkFont)}
              data-active={active}
              data-show={show && active}
              data-current={current}
              key={child.uid}
              onClick={() => menuEnter('', 0)}
              onMouseEnter={() => menuEnter(child.uid, depth)}
            >
              {child.title}
            </Link>
          );
        })}
      </div>
      {depth < 1 &&
        menus
          .filter((m) => m.children && m.children.length)
          .map((child) => {
            const active = hover.length > depth && hover[depth] === child.uid;
            return (
              <MenuItem
                key={`submenus-${child.uid}`}
                menus={child.children}
                // menuOpen={menuOpen}
                menuEnter={menuEnter}
                hover={hover}
                show={active}
                depth={depth + 1}
              />
            );
          })}
    </>
  );
};

export default MenuItem;
