import React, { FC } from "react";
import "./ParagraphPanel.css";

const ParagraphPanel: FC<ParagraphPanelDto> = ({ Title, Text }) => {
  return (
    <div className="paragraph-panel">
      {Title && (
        <h4 className="paragraph-panel__title">
          {Title.split(/\n/g).map((part, i) => (
            <React.Fragment key={i}>
              {i !== 0 ? <br /> : null}
              {part}
            </React.Fragment>
          ))}
        </h4>
      )}
      {Text && (
        <div
          className="paragraph-panel__text"
          dangerouslySetInnerHTML={{ __html: Text }}
        />
      )}
    </div>
  );
};

export default ParagraphPanel;
