import React, { FC, useState, useRef, useEffect } from 'react';
import { ClassNames } from 'src/utils/ClassNames';
import isTouchDevice from 'is-touch-device';
import classNames from 'classnames';
import Flickity from 'react-flickity-component';
import Image from '../Image';
import Lead from '../Lead/Lead';
import './Hero.css';
import arrow from '../../assets/slider-arrow.svg';

export type HeroProps = {
	Images?: PictureRef[];
	IntroTitle?: string;
	IntroText?: string;
	breadcrumb?: BreadcrumbDto[];
	Delay?: number;
};

const Hero: FC<HeroProps> = ({
	Images,
	Delay,
	IntroTitle,
	IntroText,
	breadcrumb,
}) => {
	const flickity = useRef<any>(null);
	const [current, setCurrent] = useState(0);

	const images = Images?.filter((i) => !!i) || [];
	const count = images.length;
	useEffect(() => {
		const instance = flickity.current;
		if (!instance) {
			return;
		}
		const change = (index: number) => {
			setCurrent(index % count);
		};

		// const settle = (index: number) => {
		//   if (index >= 2 * count) {
		//     instance.select((index % count) + count, false, true);
		//   } else if (index < count) {
		//     instance.select((index % count) + count, false, true);
		//   }
		// };

		// const scroll = () => {
		//   for (const slide of instance!.cells! as any) {
		//     const x = (slide.target + instance!.x) * -0.5;
		//     const img = slide.element.querySelector("img");
		//     img.style.transform = "translateX( " + x + "px)";
		//   }
		// };

		instance.on('change', change);
		// instance.on("scroll", scroll);
		// instance.on("settle", settle);

		// eslint-disable-next-line consistent-return
		return () => instance.off('change', change);
	}, [count, flickity]);

	if (Images == null) {
		return null;
	}

	const next = () => flickity.current && flickity.current.next();
	const previous = () => flickity.current && flickity.current.previous();
	const hasLegends = Images.some((img) => !!img.Legend);

	const sizes = '(max-width: 1799px) 100w, 1800px';

	return (
		<>
			<div className={ClassNames('hero', '', !Images.length && 'no-slider')}>
				{Images.length > 0 && (
					<div className='hero__slider'>
						<div className='hero__slider-inner'>
							<Flickity
								className='hero__container' // default ''
								options={{
									pageDots: false,
									prevNextButtons: false,
									setGallerySize: false,
									wrapAround: true,
									accessibility: false,
									adaptiveHeight: false,
									draggable: isTouchDevice(),
									autoPlay: Images.length > 1 ? Delay || 6000 : undefined,
								}}
								disableImagesLoaded={false} // default false
								// eslint-disable-next-line no-return-assign
								flickityRef={(r) => (flickity.current = r)}
							>
								{Images.map((s) => (
									<div key={s.Id} className='hero__slider-figure'>
										<Image
											className='hero__slider-image'
											pictureRef={s}
											sizes={sizes}
											alt=''
										/>
									</div>
								))}
							</Flickity>
						</div>
						{Images.length > 1 && (
							<nav className='hero__slider-nav'>
								<button
									type='button'
									aria-label='nextSlide'
									className={classNames(
										'hero__slider-btn',
										'hero__slider-btn--next'
									)}
									onClick={() => next()}
								>
									<img src={arrow} alt='' />
								</button>
								<button
									type='button'
									aria-label='previousSlide'
									className={classNames(
										'hero__slider-btn',
										'hero__slider-btn--previous'
									)}
									onClick={() => previous()}
								>
									<img src={arrow} alt='' />
								</button>
							</nav>
						)}
					</div>
				)}

				<div className='hero__content'>
					{hasLegends && (
						<div className='hero__slider-legends'>
							{Images.map((image, i) => {
								const active = current === i;
								const [legend, copy] = image.Legend.split('|');

								return (
									<div
										key={image.Id}
										className={ClassNames(
											'hero',
											'slider-legend',
											active && 'active'
										)}
									>
										<div className='hero__slider-legend-inner'>
											{copy !== null && copy !== '' && (
												<span
													className={ClassNames('hero', 'slider-legend-copy')}
												>
													{copy}
												</span>
											)}
											{legend !== null && legend !== '' && (
												<span
													className={ClassNames('hero', 'slider-legend-text')}
												>
													{legend}
												</span>
											)}
										</div>
									</div>
								);
							})}
						</div>
					)}
					{(IntroTitle || IntroText) && (
						<div className='hero__lead'>
							<Lead
								title={IntroTitle}
								text={IntroText}
								breadcrumb={breadcrumb}
							/>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default Hero;
