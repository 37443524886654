import React, { FC } from 'react';
import { makeStyles, createStyles } from '@material-ui/styles';
import mediaQueries from 'src/constants/mediaQueries';
import { Link } from 'react-router-dom';
import variables from 'src/constants/variables';
import logo from '../assets/logo.png';
import Menu from '../components/Menu';

const useStyles = makeStyles(() =>
	createStyles({
		header: {
			position: 'fixed',
			top: 0,
			left: '50%',
			height: 0,

			width: '100%',
			maxWidth: variables.MaxWidthWebsite,
			transform: 'translateX(-50%)',
			zIndex: 9,

			[mediaQueries.Mobile]: {
				display: 'none',
			},
			[mediaQueries.TabletPortrait]: {
				display: 'none',
			},
		},
		logo: {
			position: 'absolute',
			top: 0,

			[mediaQueries.TabletLandscape]: {
				left: variables.Gutter.Tablet,
				'& img': {
					width: 110,
				},
			},
			[mediaQueries.Laptop]: {
				left: variables.Gutter.Laptop,
				'& img': {
					width: 170,
				},
			},
			[mediaQueries.Desktop]: {
				left: variables.Gutter.Desktop,
				'& img': {
					width: 200,
				},
			},
			'@media only screen and (min-width: 1900px)': {
				left: 0,
				transform: 'translate(-30px, -1px)',
			},
		},
		menu: {
			position: 'relative',
			margin: '0 auto',

			[mediaQueries.TabletLandscape]: {
				margin: `${0} ${28 * 2 + 90}px`, // 90px` width logo
			},
			[mediaQueries.Laptop]: {
				maxWidth: variables.MaxWidthMenu.Desktop,
			},
			[mediaQueries.Desktop]: {
				maxWidth: variables.MaxWidthMenu.Desktop,
			},
			'@media only screen and (min-width: 1200px) and (max-width: 1400px)': {
				margin: `${0} ${38 * 2 + 170}px`, // 170px` width logo
			},
		},
	})
);

const Header: FC = () => {
	const cls = useStyles();

	return (
		<header className={cls.header}>
			<Link to='/' className={cls.logo}>
				<img src={logo} alt='' />
			</Link>
			<div className={cls.menu}>
				<Menu />
			</div>
		</header>
	);
};

export default Header;
