import React, { FC } from "react";
import "./PartnersPanel.css";
import { ClassNames } from "../../utils/ClassNames";
import Image from "../../components/Image";

const PartnersPanel: FC<PartnersPanelDto> = ({ Title, PartnerImages }) => {
  const sizes = "(max-width: 767px) 100w, 260px";

  return (
    <div className="partners-panel">
      {Title && <h4 className="partners-panel__title">{Title}</h4>}
      <div className="partners-panel__items">
        {PartnerImages &&
          PartnerImages.map((img, i) => (
            <div key={i} className={ClassNames("partners-panel", "item")}>
              {(img.Link === null || img.Link === "") && (
                <div className="partners-panel__item-inner">
                  <Image
                    pictureRef={img.Image}
                    className="partners-panel__image"
                    alt={img.Title}
                    sizes={sizes}
                  />
                </div>
              )}

              {img.Link !== null && img.Link !== "" && (
                <a
                  className="partners-panel__item-inner"
                  href={img.Link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    pictureRef={img.Image}
                    className="partners-panel__image"
                    alt={img.Title}
                    sizes={sizes}
                  />
                </a>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default PartnersPanel;
