import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Language } from '../../utils/Languages';
import './Lead.css';

export type leadProps = {
  title?: string;
  text?: string;
  breadcrumb?: BreadcrumbDto[];
};

const Lead: FC<leadProps> = ({ title, text, breadcrumb }) => {
  const uids =
    breadcrumb &&
    [...breadcrumb]
      .filter((e, i, arr) => i !== arr.length - 1)
      .reverse()
      .map((uid, i, arr) => ({
        Title: uid.Title,
        url: arr
          .filter((e, j) => i >= j)
          .map((e) => e.UID)
          .join('/'),
      }));

  return (
    <article className='lead'>
      {uids && (
        <div className='lead__breadcrumbs'>
          {uids.map(
            (b) =>
              b.url !== null &&
              b.Title !== null && (
                <Link
                  key={b.url}
                  to={`/${Language}/${b.url}`}
                  className='lead__breadcrumb'
                >
                  <span>{b.Title}</span>
                </Link>
              )
          )}
        </div>
      )}
      {title && <h5 className='lead__title'>{title}</h5>}
      {text && (
        <div
          className='lead__text'
          dangerouslySetInnerHTML={{ __html: text }}
        />
      )}
    </article>
  );
};

export default Lead;
