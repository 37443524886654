import React, { FC, useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { NextLanguage, Language } from '../utils/Languages';
import { useApp } from '../@context/App';
import MenuItem from './MenuItem';
import variables, { bodyFontSize } from '../constants/variables';
import stylesheet from '../constants/stylesheet';
import mediaQueries from '../constants/mediaQueries';

const topPaddingMenuTablet = `${18 / bodyFontSize.Tablet}rem`;
const topPaddingMenuLaptop = `${28 / bodyFontSize.Laptop}rem`;
const topPaddingMenuDesktop = `${28 / bodyFontSize.Desktop}rem`;

const fontSizeSecondaryMenuTablet = `${14 / bodyFontSize.Tablet}rem`;
const fontSizeSecondaryMenuLaptop = `${14 / bodyFontSize.Laptop}rem`;
const fontSizeSecondaryMenuDesktop = `${14 / bodyFontSize.Desktop}rem`;

const submenuTranslateTablet = 82;
const submenuTranslateLaptop = 118;
const submenuTranslateDesktop = 128;

const useStyles = makeStyles(() =>
	createStyles({
		root: {},
		container: {
			boxSizing: 'border-box',

			'&:before': {
				content: '""',
				position: 'absolute',
				top: 0,
				left: 0,
				right: 0,
				background: 'white',
				zIndex: '-1',
				...stylesheet.boxShadow,
			},
			[mediaQueries.TabletLandscape]: {
				paddingTop: topPaddingMenuTablet,
				'&:before': {
					height: submenuTranslateTablet,
				},
			},
			[mediaQueries.Laptop]: {
				paddingTop: topPaddingMenuLaptop,
				'&:before': {
					height: submenuTranslateLaptop,
				},
			},
			[mediaQueries.Desktop]: {
				paddingTop: topPaddingMenuDesktop,
				'&:before': {
					height: submenuTranslateDesktop,
				},
			},
		},
		secondaryLink: {
			display: 'inline-block',
			marginRight: 10,

			'&:before': {
				...stylesheet.InternalLink,
			},

			[mediaQueries.TabletLandscape]: {
				fontSize: fontSizeSecondaryMenuTablet,
			},
			[mediaQueries.Laptop]: {
				fontSize: fontSizeSecondaryMenuLaptop,
			},
			[mediaQueries.Desktop]: {
				fontSize: fontSizeSecondaryMenuDesktop,
			},
		},
		secondaryLinkLang: {
			textTransform: 'uppercase',
		},
		secondaryContainer: {
			[mediaQueries.AfterMobile]: {
				display: 'flex',
				justifyContent: 'space-between',
				marginBottom: `${23 / bodyFontSize.Mobile}rem`,
			},
			[mediaQueries.TabletLandscape]: {
				marginBottom: `${15 / bodyFontSize.Tablet}rem`,

				'&:first-child': {
					padding: `0 ${variables.Gutter.Tablet}rem`,
				},
			},
			[mediaQueries.Laptop]: {
				marginBottom: `${23 / bodyFontSize.Laptop}rem`,

				'&:first-child': {
					padding: `0 ${variables.Gutter.Laptop}rem`,
				},
			},
			[mediaQueries.Desktop]: {
				marginBottom: `${23 / bodyFontSize.Desktop}rem`,

				'&:first-child': {
					padding: `0 ${variables.Gutter.Desktop}rem`,
				},
			},
		},
		secondary: {},
		social: {},
		socialLink: {
			display: 'inline-block',
			marginLeft: 10,
			...stylesheet.ExternalLink,

			[mediaQueries.TabletLandscape]: {
				fontSize: fontSizeSecondaryMenuTablet,
			},
			[mediaQueries.Laptop]: {
				fontSize: fontSizeSecondaryMenuLaptop,
			},
			[mediaQueries.Desktop]: {
				fontSize: fontSizeSecondaryMenuDesktop,
			},
		},
		newsletterLink: {
			display: 'inline-block',
			marginLeft: 10,

			'&:before': {
				...stylesheet.InternalLink,
			},

			[mediaQueries.TabletLandscape]: {
				fontSize: fontSizeSecondaryMenuTablet,
			},
			[mediaQueries.Laptop]: {
				fontSize: fontSizeSecondaryMenuLaptop,
			},
			[mediaQueries.Desktop]: {
				fontSize: fontSizeSecondaryMenuDesktop,
			},
		},

		[mediaQueries.TabletLandscape]: {
			fontSize: fontSizeSecondaryMenuTablet,
		},
		[mediaQueries.Laptop]: {
			fontSize: fontSizeSecondaryMenuLaptop,
		},
		[mediaQueries.Desktop]: {
			fontSize: fontSizeSecondaryMenuDesktop,
		},
		menuPrimary: {
			[mediaQueries.AfterMobile]: {
				display: 'flex',
				flexWrap: 'wrap',
			},
		},
	})
);

const Menu: FC = () => {
	const cls = useStyles();
	const { app } = useApp();
	const [hover, setHover] = useState<string[]>([]);
	const [menuOpen, setMenuOpen] = useState<boolean>();

	const handleMenuEnter = (uid: string, depth: number) => {
		const newHover = [...hover.filter((h, i) => i < depth), uid];
		setHover(newHover);
		setMenuOpen(true);
	};

	const handleMenuLeave = () => () => {
		setHover([]);
		setMenuOpen(false);
	};

	useEffect(() => {
		document.body.setAttribute('data-hide', `${menuOpen}`);
	}, [menuOpen]);

	if (!app) {
		return null;
	}

	return (
		<nav className={cls.root}>
			<div className={cls.container} onMouseLeave={handleMenuLeave()}>
				<div className={cls.secondaryContainer}>
					<div className={cls.secondary}>
						<a
							className={classNames(cls.secondaryLink, cls.secondaryLinkLang)}
							href={`?switch-lang=${NextLanguage}`}
						>
							{NextLanguage}
						</a>
						{app.Menus.secondaryItems.map((menuItem) => (
							<Link
								key={menuItem.uid}
								to={menuItem.url}
								className={cls.secondaryLink}
								onClick={handleMenuLeave()}
							>
								{menuItem.title}
							</Link>
						))}
						<Link
							to={`/${Language}/${app.Menus.Search.uid}`}
							className={cls.secondaryLink}
							onClick={handleMenuLeave()}
						>
							{app.Menus.Search.title}
						</Link>
					</div>
					<div className={cls.social}>
						{app.Menus.social.map((l) => (
							<a
								key={`${l.href}`}
								href={l.href}
								className={cls.socialLink}
								target='_blank'
								rel='noopener noreferrer'
							>
								{l.title}
							</a>
						))}
						{app.Menus.Newsletter && (
							<Link
								to={app.Menus.Newsletter.url}
								className={cls.newsletterLink}
							>
								{app.Menus.Newsletter.title}
							</Link>
						)}
					</div>
				</div>
				<div className={cls.menuPrimary}>
					<MenuItem
						menus={app.Menus.menuItems}
						// menuOpen={setMenuOpen}
						menuEnter={handleMenuEnter}
						depth={0}
						hover={hover}
						show
					/>
				</div>
			</div>
		</nav>
	);
};

export default Menu;
