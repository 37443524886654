import * as React from "react";
import { Link } from "react-router-dom";
import useReactRouter from "use-react-router";
import "./MenuMobile.css";
import { FC, useState } from "react";
import { ClassNames } from "src/utils/ClassNames";

export type MenuMobileItemProp = {
  menu: MenuDto;
  setMenuOpen: (click: boolean) => void;
};

const MenuMobileItem: FC<MenuMobileItemProp> = ({ menu, setMenuOpen }) => {
  const { location } = useReactRouter();
  const [open, setOpen] = useState(false);
  const { children } = menu;
  const haschildren = children.length >= 1;
  const current = location.pathname.indexOf(menu.url) === 0;

  // const handleClick = (uid: string) => {
  //   const menus = openItems;
  //   const i = menus.indexOf(uid);

  //   if (i !== -1) {
  //     menus.splice(i, 1);
  //     setOpenItems(menus);

  //     return;
  //   }
  //   setOpenItems([...menus, uid]);
  // };

  return (
    <div
      className={ClassNames(
        "menu-mobile",
        "item",
        open && "show",
        current && "current"
      )}
    >
      <Link
        key={menu.uid}
        to={menu.url}
        className="menu-mobile__link"
        onClick={() => setMenuOpen(false)}
      >
        {menu.title}
      </Link>
      {haschildren && (
        <>
          <button
            type="button"
            aria-label={`${menu.title} toggle`}
            className="menu-mobile__toggle-submenu"
            onClick={() => setOpen(!open)}
          />
          <div className="menu-mobile__submenu">
            {children.map((child) => {
              return (
                <MenuMobileItem
                  menu={child}
                  key={child.uid}
                  setMenuOpen={(click) => setMenuOpen(click)}
                />
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};
export default MenuMobileItem;
