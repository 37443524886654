/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useCallback, useRef, useState } from "react";
import "./AccordionPanel.css";
import Panel from "../Panel";
import useElementSize from "../../utils/useElementSize";
import accordionDisclosurePlus from "../../assets/accordion-disclosure-plus.svg";
import accordionDisclosureMinus from "../../assets/accordion-disclosure-minus.svg";
import classNames from "classnames";

const AccordionItem: FC<AccodionItemDto> = ({ Title, Content }) => {
  const [open, setOpen] = useState(false);
  const bodyRef = useRef<HTMLElement | null>(null);
  const bodySize = useElementSize(bodyRef);

  const onClickTitle = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, []);

  const onClickDisclosureButton = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    setOpen((prevOpen) => !prevOpen);
  }, []);

  return (
    <div
      className={classNames(
        "accordion-panel__item",
        open ? "accordion-panel__item_open" : "accordion-panel__item_closed"
      )}
    >
      <h3 className="accordion-panel__item-title" onClick={onClickTitle}>
        {Title}
        <button
          title={open ? "Hide content" : "Show content"}
          onClick={onClickDisclosureButton}
          type="button"
          className="accordion-panel__disclosure-button"
        >
          {open ? (
            <img
              className="accordion-panel__disclosure-icon"
              src={accordionDisclosureMinus}
              alt="Hide content"
              aria-hidden
            />
          ) : (
            <img src={accordionDisclosurePlus} alt="Show content" aria-hidden />
          )}
        </button>
      </h3>
      <div
        className="accordion-panel__item-body"
        style={{
          height: open ? bodySize.height : 0,
          overflow: "hidden",
          transition: `height ${Math.max(
            250,
            Math.min(bodySize.height, 500)
          )}ms`,
        }}
      >
        <div ref={bodyRef as any}>
          {Content.map((content, i) => {
            return (
              <div className="accordion-panel__item-panel" key={i}>
                <Panel key={i} {...(content as IPanelDto)} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const AccordionPanel: FC<AccordionPanelDto> = ({ AccordionItems }) => {
  return (
    <div className="accordion-panel">
      {AccordionItems.map((item, i) => {
        return <AccordionItem {...item} key={i} />;
      })}
    </div>
  );
};

export default AccordionPanel;
