import React, { FC } from "react";
import "./ImpressumPage.css";
import { ClassNames } from "../../utils/ClassNames";
import Lead from "../../components/Lead/Lead";

const ImpressumPage: FC<ImpressumPageDto> = ({
  IntroTitle,
  IntroText,
  TextPanels,
}) => {
  return (
    <div className="impressum">
      <div className="impressum__content">
        {(IntroTitle || IntroText) && (
          <div className="impressum__lead">
            <Lead title={IntroTitle} text={IntroText} />
          </div>
        )}
        {TextPanels && (
          <div className="impressum__panels">
            <div className="impressum__panels-inner">
              {TextPanels.map((panel, i) => (
                <div
                  key={i}
                  className={ClassNames(
                    "impressum",
                    "panel",
                    panel.Size === "Half" && "half"
                  )}
                  dangerouslySetInnerHTML={{ __html: panel.Text }}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImpressumPage;
