import * as React from "react";
import { useContext, useEffect, useState } from "react";
import format from "string-format";

type Ti18n = {
  translate: (
    key: string,
    values?: Record<string, unknown> | string[] | string
  ) => string;
};
const languages = ["fr", "de"];
export const I18nContext = React.createContext<Ti18n>({} as never);

type Props = { language: string };
export const I18n: React.FC<Props> = ({ children, language }) => {
  const [dict, setDict] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    const lang = languages.includes(language) ? language : "fr";
    (async () => {
      const result = await import(`../i18n/${lang}.json`);
      setDict(result);
    })();
  }, [language]);

  return (
    <I18nContext.Provider
      value={{
        translate: (key, values) => {
          if (!dict) {
            return key;
          }

          if (values) {
            return format(dict[key], values);
          }

          return dict[key];
        },
      }}
    >
      {children}
    </I18nContext.Provider>
  );
};

export const useI18n = () => useContext(I18nContext);
