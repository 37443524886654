import * as React from 'react';
import './HeaderMobile.css';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ClassNames } from 'src/utils/ClassNames';
import logo from '../../assets/logo.png';
import burguer from '../../assets/burguer.svg';
import close from '../../assets/close.png';
import MenuMobile from '../MenuMobile/MenuMobile';

const HeaderMobile: React.FC = () => {
	const [menuOpen, setMenuOpen] = useState(false);

	useEffect(() => {
		document.body.classList.toggle('body--menu-mobile', menuOpen);
	}, [menuOpen]);

	return (
		<header className='header-mobile'>
			<Link to='/' className='header-mobile__logo'>
				<img src={logo} alt='' className='header-mobile__logo-svg' />
			</Link>
			<button
				type='button'
				className={ClassNames('header-mobile', 'burguer', menuOpen && 'active')}
				onClick={() => setMenuOpen(!menuOpen)}
			>
				{menuOpen && (
					<img src={close} alt='' className='header-mobile__burguer-svg' />
				)}
				{!menuOpen && (
					<img src={burguer} alt='' className='header-mobile__burguer-svg' />
				)}
			</button>
			<div className={ClassNames('header-mobile', 'menu', menuOpen && 'show')}>
				<MenuMobile setMenuOpen={(click) => setMenuOpen(click)} />
			</div>
		</header>
	);
};
export default HeaderMobile;
