export const mobileSize = 767;
export const tabletSize = 1199;
export const desktopSize = 1800;

const mediaQueries = {
  Mobile: `@media screen and (max-width: ${mobileSize}px)`,
  AfterMobile: `@media screen and (min-width: ${mobileSize + 1}px)`,
  Tablet: `@media screen and (min-width: ${
    mobileSize + 1
  }px) and (max-width: ${tabletSize}px)`,
  TabletPortrait: `@media screen and (min-width: ${
    mobileSize + 1
  }px) and (max-width: ${tabletSize}px) and (orientation: portrait)`,
  TabletLandscape: `@media screen and (min-width: ${
    mobileSize + 1
  }px) and (max-width: ${tabletSize}px) and (orientation: landscape)`,
  AfterTablet: `@media screen and (min-width: ${tabletSize + 1}px)`,
  Laptop: `@media screen and (min-width: ${tabletSize + 1}px) and (max-width: ${
    desktopSize - 1
  }px)`,
  Desktop: `@media screen and (min-width: ${desktopSize}px)`,
};

export default mediaQueries;
