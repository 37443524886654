import React, { FC } from "react";
import "./InfosPanel.css";
import { ClassNames } from "../../utils/ClassNames";

const Info: React.FC<InfoPanelDto> = ({ Text }) => {
  return (
    <div className="info-panel" dangerouslySetInnerHTML={{ __html: Text }} />
  );
};

const InfosPanel: FC<InfosPanelDto> = ({ InfoPanels }) => {
  return (
    <div className="infos-panel">
      <div className="infos-panel__inner">
        {InfoPanels.map((p, i) => (
          <div
            key={i}
            className={ClassNames(
              "infos-panel",
              "item",
              p.Size === "Third" && "third",
              p.Size === "Full" && "full",
              p.Size === "TwoThird" && "two-third",
              p.Size === "Half" && "half"
            )}
          >
            <div className="infos-panel__item-inner">
              <Info Text={p.Text} Size={p.Size} type="" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InfosPanel;
