import React, { FC, useState, useEffect } from "react";
import BoxItem from "../../components/BoxItem/BoxItem";
import Calendar from "../../components/Calendar/Calendar";
import Hero from "../../components/Hero/Hero";
import "./StartPage.css";

const StartPage: FC<StartPageDto> = ({
  boxes,
  KalendarPage,
  IntroTitle,
  IntroText,
  Delay,
  Images,
}) => {
  const [shuffleImages, setShuffleImages] = useState<PictureRef[]>();

  useEffect(() => {
    if (!Images) {
      return;
    }

    (() => {
      let currentIndex = Images.length;
      let temporaryValue: PictureRef;
      let randomIndex = 0;

      // While there remain elements to shuffle...
      while (currentIndex !== 0) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = Images[currentIndex];
        Images[currentIndex] = Images[randomIndex];
        Images[randomIndex] = temporaryValue;
      }
      setShuffleImages(Images);
    })();
  }, [Images]);

  return (
    <section className="startpage">
      <Hero
        Images={shuffleImages}
        IntroTitle={IntroTitle}
        IntroText={IntroText}
        Delay={Delay}
      />
      <div className="startpage__content">
        <div className="startpage__items">
          {boxes.map((box, i) => (
            <div key={i} className="startpage__item">
              <BoxItem {...box} />
            </div>
          ))}
        </div>
        {KalendarPage && <Calendar {...KalendarPage} />}
      </div>
    </section>
  );
};

export default StartPage;
